<template>
  <div class="product-evaluation">
    <div class="sctp-container pad-tb10">
      <div class="sctp-bg-white" style="min-height: 60vh;">
        <comment :id="id" :type="type"></comment>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Evaluation",
  components: {
    Comment: () => import('@PAGE/shop-manage/production-manage/production-commnet/CommentContent'),
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
